"use client";
import { useEffect, useReducer, Reducer, useMemo } from 'react';
import cache from './globalCache';

export type DataFetchState<T> = {
  isLoading: boolean;
  isError: boolean;
  data: T | null;
  error: string | null;
};

type DataFetchAction<T> =
  | { type: 'FETCH_INIT' }
  | { type: 'FETCH_SUCCESS'; payload: T }
  | { type: 'FETCH_FAILURE'; payload: string }
  | { type: 'RESET'; payload: T };

function dataFetchReducer<T>(
  state: DataFetchState<T>,
  action: DataFetchAction<T>
): DataFetchState<T> {
  switch (action.type) {
    case 'FETCH_INIT':
      return { ...state, isLoading: true, isError: false };
    case 'FETCH_SUCCESS':
      return { ...state, isLoading: false, isError: false, data: action.payload, error: null };
    case 'FETCH_FAILURE':
      return { ...state, isLoading: false, isError: true, data: null, error: action.payload };
    case 'RESET':
      return { isLoading: false, isError: false, data: action.payload, error: null };
    default:
      throw new Error();
  }
};
function useFetch<T>(initialData: T, request: RequestInfo, deps: React.DependencyList = [], abort?: boolean): DataFetchState<T> {
  const [state, dispatch] = useReducer<Reducer<DataFetchState<T>, DataFetchAction<T>>>(dataFetchReducer, {
    isLoading: true,
    isError: false,
    data: initialData,
    error: null,
  });

  // const memoizedRequest = useMemo(() => request, [request, ...deps]);

  useEffect(() => {
    console.log("Fetching data for", request);
    if (abort || !request) return;
  
    let didCancel = false;
    // const cacheKey = typeof request === 'string' ? request : JSON.stringify(request);
    const abortController = new AbortController();
    const fetchData = async () => {
      // if (cache.has(cacheKey)) {
      //   dispatch({ type: 'FETCH_SUCCESS', payload: cache.get(cacheKey) });
      //   console.log("FROM CACHE");
      //   return;
      // }
      dispatch({ type: 'FETCH_INIT' });
      try {
        const response = await fetch(request, { signal: abortController.signal });
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const result: T = await response.json();
        if (!didCancel) {
          dispatch({ type: 'FETCH_SUCCESS', payload: result });
          // cache.set(cacheKey, result);
        }
      } catch (error) {
        if (!didCancel) {
          dispatch({ type: 'FETCH_FAILURE', payload: error instanceof Error ? error.message : 'An unexpected error occurred' });
        }
      }
    };
  
    fetchData();
    return () => {
      console.log("Cancelling fetch for", request);
      didCancel = true;
      abortController.abort();
    };
  }, [request, abort, ...deps]);
  

  useEffect(() => {
    if (JSON.stringify(initialData) !== JSON.stringify(state.data)) {
      dispatch({ type: 'RESET', payload: initialData });
    }
  }, [initialData]);

  return state;
};
export default useFetch;
