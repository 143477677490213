/**
 * Utils for formatting and generating dates or values involving dates.
 */

import { Sport } from "common";

/**
 * @param input the date as a UTC string or Date object
 * @param day whether the day is included
 * @param year whether the year is included
 * @param hideCurrentYear shows the year only if it's not the current year
 * @param sport applies sport-specific day formatting
 * @returns a formatted date that has been converted to the client's timezone
 */
export function formatDate(
  input: string | Date,
  day?: boolean,
  year?: boolean,
  hideCurrentYear?: boolean
  // sport?: Sport
): string {
  const date: Date = typeof input === "string" ? new Date(input) : input;
  const cmpDate = date;
  const today = new Date();
  const yesterday = new Date(today);
  const tomorrow = new Date(today);
  yesterday.setDate(yesterday.getDate() - 1);
  tomorrow.setDate(tomorrow.getDate() + 1);

  today.setHours(0, 0, 0, 0);
  cmpDate.setHours(0, 0, 0, 0);
  tomorrow.setHours(0, 0, 0, 0);
  yesterday.setHours(0, 0, 0, 0);

  let dayLabel: string | undefined = undefined;
  if (cmpDate.getTime() === today.getTime()) {
    dayLabel = "Today";
  } else if (cmpDate.getTime() === tomorrow.getTime()) {
    dayLabel = "Tomorrow";
  } else if (cmpDate.getTime() === yesterday.getTime()) {
    dayLabel = "Yesterday";
  }

  let options: Intl.DateTimeFormatOptions = {
    weekday: day && !dayLabel ? "short" : undefined,
    month: "short",
    day: "2-digit",
    year: year ? "2-digit" : undefined,
  };

  let append: boolean = false;
  if (year && hideCurrentYear) {
    const currentDate = new Date();
    const isCurrentYear = date.getFullYear() === currentDate.getFullYear();
    if (isCurrentYear) {
      options.year = undefined;
    } else {
      append = true;
    }
  }

  // return `${new Intl.DateTimeFormat("en-US", options).format(date)}${append ? "'" : ""}`;
  const formattedDate = `${new Intl.DateTimeFormat("en-US", options).format(
    date
  )}${append ? "'" : ""}`;

  // Replace the 'weekday' part with dayLabel if available
  return dayLabel ? formattedDate.replace(/^[^,]+/, dayLabel) : formattedDate;
}
/**
 * TODO: replace with `toDateString()` after server switches to UTC.
 */
export function formatServerDate(date: Date): string {
  const formatter = new Intl.DateTimeFormat("en-US", {
    timeZone: "America/New_York",
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });
  const formattedDate = formatter.format(date);
  const [month, day, year] = formattedDate.split("/");
  return `${year}-${month}-${day}`;
}

/**
 * @param input an ISO string or Date.
 * @returns formatted time.
 */
export function formatTime(
  input: string | Date,
  includeTimezone: boolean = false
): string {
  let date: Date;

  if (typeof input === "string") {
    date = new Date(input);
  } else if (input instanceof Date) {
    date = input;
  } else {
    throw new Error("Invalid input type: Expected a string or Date object.");
  }

  let hours = date.getHours();
  let minutes = date.getMinutes();
  const formattedMinutes: string =
    minutes < 10 ? "0" + minutes : minutes.toString();
  const ampm = hours >= 12 ? "PM" : "AM";
  let adjustedHours = hours % 12;
  adjustedHours = adjustedHours || 12;

  let result = `${adjustedHours}:${formattedMinutes} ${ampm}`;
  if (includeTimezone) {
    const timezone = new Intl.DateTimeFormat("en-US", { timeZoneName: "short" })
      .formatToParts(date)
      .find((part) => part.type === "timeZoneName")?.value;
    if (timezone) {
      result += ` ${timezone}`;
    }
  }

  return result;
}

export function getLocalISOFromUTC(utcDateString: string) {
  const date = new Date(utcDateString);
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();

  const localISO = `${year}-${month.toString().padStart(2, "0")}-${day
    .toString()
    .padStart(2, "0")}T${hours.toString().padStart(2, "0")}:${minutes
    .toString()
    .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;

  return localISO;
}

// export function convertToClientTimeISO(utcDateString: string) {
//   const clientTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
//   const convertedDate = moment.utc(utcDateString).tz(clientTimeZone).format();
//   const timeZoneAbbreviation = moment.tz(clientTimeZone).format("z");

//   return { date: convertedDate, timeZone: timeZoneAbbreviation };
// }

export function convertDateToStringWithoutTime(date: Date): string {
  const pad = (num: number) => num.toString().padStart(2, "0");
  const year = date.getFullYear();
  const month = pad(date.getMonth() + 1);
  const day = pad(date.getDate());

  return `${year}-${month}-${day}`;
}

/**
 * @returns a UTC date string without a time, e.g., 2024-04-25
 */
export function toServerDateString(date: string): string {
  return convertISOToUTC(toISOStartOfDay(date));
}

export function convertISOToUTC(isoString: string): string {
  const date = new Date(isoString);
  return date.toISOString();
}

/**
 * a DateString is specifically an ISO string without a time.
 * used typically for server requests and URLs.
 */
export function toDateString(isoString: string): string {
  return isoString.split("T")[0];
}

/**
 * removes time from an ISO string and then appends a zeroed time.
 */
export function toISOStartOfDay(isoString: string): string {
  return `${toDateString(isoString)}T00:00:00`;
}

export function getYearFromDateString(dateString: string): string {
  return dateString.substring(0, 4);
}

/**
 * @returns an array of year optoins specific to each sport and their season ranges.
 */
export function generateYearOptions(
  sport: Sport,
  startYearString: string,
  count: number
): Option[] {
  const showRange: boolean = sport !== "mlb";
  const addYear: boolean = sport !== "nfl";
  const startYear = parseInt(startYearString, 10);
  if (isNaN(startYear)) {
    console.error("Invalid startYear. Please provide a valid numeric year.");
    return [];
  }
  const options: Option[] = [];
  for (let i = 0; i < count; i++) {
    const year = showRange && addYear ? startYear - 1 - i : startYear - i;
    const nextYear = String(year + 1).slice(-2);
    options.push({
      label: showRange ? `${year}-${nextYear}'` : `${year}`,
      value: `${showRange && addYear ? year + 1 : year}`,
    });
  }
  return options;
}

/**
 * @returns a formatted year or year range specific to the sport and season.
 */
export function formatSeasonYear(sport: Sport, year: string): string {
  const addYear: boolean = sport === "nfl";
  if (sport === "mlb") {
    return year;
  }
  const thisYear = parseInt(year, 10);
  const nextYear = String(addYear ? thisYear + 1 : thisYear).slice(-2);
  return `${addYear ? thisYear : thisYear - 1}-${nextYear}'`;
}
