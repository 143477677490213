'use client';
import { AppProgressBar as ProgressBar } from 'next-nprogress-bar';

const ProgressBarProvider = ({ children }: {children: React.ReactNode}) => {
  return (
    <>
      {children}
      <ProgressBar
        height="4px"
        color="var(--primary-500)"
        options={{ showSpinner: false }}
        shallowRouting={true}
      />
    </>
  );
};

export default ProgressBarProvider;