"use client";
import React, { createContext, useContext, useState, useEffect } from 'react';
import { usePathname } from "next/navigation";

type ScrollContextType = {
  disableScroll: boolean;
  setDisableScroll: (value: boolean) => void;
};

const ScrollContext = createContext<ScrollContextType>({
  disableScroll: false,
  setDisableScroll: () => {},
});
export const useScrollControl = () => useContext(ScrollContext);

export const ScrollProvider = ({ children }: {children: React.ReactNode}) => {
  const [disableScroll, setDisableScroll] = useState(false);
  const pathname = usePathname();
  const [previousPath, setPreviousPath] = useState("");

   const specialRoutes = ['/scores/', '/standings', '/profile', '/teams/', '/picks', '/betting-guide/'];

   const isSpecialRoute = (path: string) => {
     return specialRoutes.some(route => path.includes(route));
   };
   React.useEffect(() => {
    if (disableScroll) return;

    const shouldScrollToTop = () => {
      const onSpecialRouteNow = isSpecialRoute(pathname);
      const wasOnSpecialRouteBefore = isSpecialRoute(previousPath);
      return (onSpecialRouteNow && !wasOnSpecialRouteBefore) || (!onSpecialRouteNow && wasOnSpecialRouteBefore) || (!onSpecialRouteNow && !wasOnSpecialRouteBefore);
    };
    if (shouldScrollToTop()) {
      window.scroll(0, 0);
    }
    setPreviousPath(pathname);
   }, [pathname, previousPath, disableScroll]);

  return (
    <ScrollContext.Provider value={{ disableScroll, setDisableScroll }}>
      {children}
    </ScrollContext.Provider>
  );
};

export default ScrollProvider;
