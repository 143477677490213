"use client";
import React, { createContext, useContext, useState, useEffect } from "react";
import { ApiResponse, Scoreboard, ScoreboardGame } from "common";
import useFetch from "../fetch";
import {
  GameCounts,
  GamesBySport,
  GamesByStatus,
  buildScoreboardRequest,
  calculateGameCounts,
  defaultGameCounts,
  defaultGamesByStatus,
  groupGamesBySport,
  groupGamesBySport2,
  groupGamesByStatus2,
} from "../game";
import useScoreboardUpdate from "../hooks/useScoreboardUpdate";
import { convertDateToStringWithoutTime, formatDate } from "../date";
import { usePathname } from "next/navigation";
import useWebSocket from "../useWebSocket";
import { useWebSocketContext } from "../websocket-provider";

interface ScoreboardContextType {
  games: ScoreboardGame[] | null | undefined;
  gameCounts: GameCounts;
  gamesBySport: GamesBySport;
  gamesByStatus: GamesByStatus;
  isLoading: boolean;
  isError: boolean;
  error: string | null;
  dateOptions: Option[];
  selectedDate: string | undefined;
  setSelectedDate: React.Dispatch<React.SetStateAction<string | undefined>>;
}

const ScoreboardContext = createContext<ScoreboardContextType>({
  games: undefined,
  gameCounts: defaultGameCounts(),
  gamesBySport: {},
  gamesByStatus: defaultGamesByStatus(),
  isLoading: true,
  isError: false,
  error: null,
  dateOptions: [],
  selectedDate: undefined,
  setSelectedDate: () => {},
});

export const useScoreboard = () => {
  const context = useContext(ScoreboardContext);
  if (!context) {
    throw new Error("useScoreboard must be used within a ScoreboardProvider");
  }
  return context;
};

export function ScoreboardProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const pathname = usePathname();
  const { isConnected } = useWebSocketContext();
  const [games, setGames] = useState<ScoreboardGame[]>([]);
  const [gamesByStatus, setGamesByStatus] = useState<GamesByStatus>(defaultGamesByStatus());
  const [gamesBySport, setGamesBySport] = useState<GamesBySport>({});
  const [statusUpdated, setStatusUpdated] = useState<boolean>(false);
  const [today, setToday] = React.useState<Date | undefined>(undefined);
  const [selectedDate, setSelectedDate] = React.useState<string | undefined>(
    undefined
  );
  const request: string = React.useMemo(() => {
    if (!selectedDate || !today) return "";
    return buildScoreboardRequest("all", selectedDate, false);
  }, [selectedDate, today]);
  const { data, isLoading, isError, error } = useFetch<
    ApiResponse<Scoreboard> | undefined
  >(undefined, request, [isConnected], !request);

  // Apply scoreboard updates.
  useScoreboardUpdate(games, setGames, setStatusUpdated);

  const gameCounts = React.useMemo(() => {
    if (games.length > 0) {
      return calculateGameCounts(games);
    }
    return defaultGameCounts();
  }, [games.length]);


  // Set today's date on mount so date options can be built.
  // Also triggers if the route changes.
  useEffect(() => {
    const now = new Date();
    setToday(now);
    const formattedToday = convertDateToStringWithoutTime(now);
    setSelectedDate(formattedToday);
    
  }, [pathname]);

  // Any time new data is requested, update the corresponding dated games.
  useEffect(() => {
    if (data?.data) {
      setGames(data.data.games);
      if (!data.data.games.length) return; 
      setGamesBySport(groupGamesBySport2(data.data.games));
      setGamesByStatus(groupGamesByStatus2(data.data.games));
    }
  }, [data]);



  // const fetchInitialScoreboard = async () => {
  //   const response = await fetch(request);
  //   const data = await response.json();
  //   if (data?.data) {
  //     setGames(data.data.games);
  //     if (!data.data.games.length) return; 
  //     setGamesBySport(groupGamesBySport2(data.data.games));
  //     setGamesByStatus(groupGamesByStatus2(data.data.games));
  //   }
  // };

  const dateOptions = React.useMemo(() => {
    if (!today) return [];
    return createDateOptions(today);
  }, [today]);


  function createDateOptions(inputDate?: Date): Option[] {
    const baseDate = inputDate ? new Date(inputDate) : new Date();
    baseDate.setHours(0, 0, 0, 0);
    const yesterday = new Date(baseDate);
    yesterday.setDate(yesterday.getDate() - 1);

    const dateOptions: Option[] = [
      {
        label: "Yesterday",
        value: convertDateToStringWithoutTime(yesterday),
        index: 0,
      },
      {
        label: "Today",
        value: convertDateToStringWithoutTime(baseDate),
        index: 1,
      },
    ];

    const getNextDays = (count: number): Option[] => {
      const nextDateOptions: Option[] = [];
      for (let i = 1; i <= count; i++) {
        const nextDay = new Date(baseDate);
        nextDay.setDate(baseDate.getDate() + i);
        const option: Option = {
          label: formatDate(nextDay.toISOString(), true),
          value: convertDateToStringWithoutTime(nextDay),
          index: i + 1,
        };
        nextDateOptions.push(option);
      }
      return nextDateOptions;
    };
    return [...dateOptions, ...getNextDays(6)];
    
  }
 


  return (
    <ScoreboardContext.Provider
      value={{
        games,
        gameCounts,
        gamesBySport,
        gamesByStatus,
        isLoading,
        isError,
        error,
        dateOptions,
        selectedDate,
        setSelectedDate,
      }}
    >
      {children}
    </ScoreboardContext.Provider>
  );
}
