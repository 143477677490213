"use client";
import { usePathname, useRouter } from 'next/navigation';
import { createContext, useContext } from 'react';

/// This is used solely to accomodate storybook as the next/navigation module doesn't like being used outside the app directory.

const NavigationContext = createContext({
  onNavigate: (path: string) => {},
  pathname: ''
});

export const useNavigation = () => useContext(NavigationContext);

export const NavigationProvider = ({ children }: {children: React.ReactNode}) => {
  const router = useRouter();
  const pathname = usePathname();

  const onNavigate = (path: string) => {
    router.replace(path, {scroll: true});
  };

  return (
    <NavigationContext.Provider value={{ onNavigate, pathname }}>
      {children}
    </NavigationContext.Provider>
  );
};